import { CircularProgress } from '@chakra-ui/react';
import { graphql, PageProps } from 'gatsby';
import React from 'react';

import { SearchQuery } from '../../../graphql-types';
import Layout from '../../components/Layout';
import Search from '../../components/Search';
import { WLBreadcrumb, WLContainer, WLH1V2 } from '../../design-library';
import { useSearchData } from '../../hooks/search.hooks';

interface SearchPageProps extends PageProps<SearchQuery> {}

const SearchPage: React.FC<SearchPageProps> = ({ data, location }) => {
  const [index, store] = useSearchData(
    data.localSearchFullSite?.publicIndexURL,
    data.localSearchFullSite?.publicStoreURL,
  );
  const loading = !index || !store;

  return (
    <Layout seo={{ title: 'Search' }} currentPath={location.pathname}>
      <WLContainer
        width="EXTRA_WIDE"
        align="start"
        paddingX={{ base: 4, lg: 8 }}
        paddingTop={{ base: 2, lg: 2 }}
        paddingBottom={{ base: 3, lg: 3 }}
      >
        <WLBreadcrumb
          entries={[{ label: 'Home', link: '/' }, { label: 'Search' }]}
        />
      </WLContainer>
      <WLContainer width="STANDARD">
        <WLH1V2>Search</WLH1V2>

        {loading ? (
          <CircularProgress />
        ) : (
          <Search index={index} store={store} />
        )}
      </WLContainer>
    </Layout>
  );
};

export const pageQuery = graphql`
  query Search {
    localSearchFullSite {
      publicIndexURL
      publicStoreURL
    }
  }
`;

export default SearchPage;
