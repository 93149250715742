import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  VStack,
} from '@chakra-ui/react';
import React, { ChangeEventHandler } from 'react';
import { useFlexSearch } from 'react-use-flexsearch';

import { WLLink, WLText } from '../../design-library';

interface SearchProps {
  index: string;
  store: object;
}

type Result = {
  id: string;
  path: string;
  title: string;
};

const Search: React.FC<SearchProps> = ({ index, store }) => {
  const [search, setSearch] = React.useState('');
  const handleSearch: ChangeEventHandler<HTMLInputElement> = React.useCallback(
    (event) => {
      setSearch(event.target.value);
    },
    [],
  );

  let results: Result[] = useFlexSearch(search, index, store, {
    limit: 20,
  });

  return (
    <>
      <Box width="full" paddingY={8} paddingX={2}>
        <InputGroup size="lg">
          <InputLeftElement
            pointerEvents="none"
            color="blue.800"
            children={<SearchIcon />}
          />
          <Input variant="flushed" onChange={handleSearch} />
        </InputGroup>
      </Box>

      <VStack paddingY={4} paddingX={2} spacing={4} alignItems="start">
        {search && (!results || results.length === 0) && (
          <WLText>No results found.</WLText>
        )}
        {results.map((result, index) => (
          <WLLink key={index} to={result.path}>
            {result.title}
          </WLLink>
        ))}
      </VStack>
    </>
  );
};

export default Search;
